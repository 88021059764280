$(document).foundation();


$(document).ready(function() {
    var url = window.location.href;
    var title = document.title;
    $('.social-fb a').attr('href', 'https://www.facebook.com/sharer/sharer.php?u=' + url);
    $('.social-tw a').attr('href', 'https://twitter.com/share?url=' + url);
    $('.social-xi a').attr('href', 'https://www.xing-share.com/app/user?op=share;sc_p=xing-share;url=' + url);
    $('.social-li a').attr('href', 'http://www.linkedin.com/shareArticle?mini=true&url=' + url + '&title=' + title);
    $('.social-wa a').attr('href', 'whatsapp://send?text=' + url);
    $('.social-email a').attr('href', 'mailto:?body=' + url);

})
